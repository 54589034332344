import { RouteConstants } from "@/router/router-constants";
import { Route } from "vue-router";
import { CurrentUserInfo, securityService } from "@/shared/security/security-service";
import { permissionService } from "@/shared/security/permission-service";

export async function authGuard(to: Route, from: Route, next: any) {
  const hasValidBearerToken = await securityService.validateBearerToken(securityService.bearerToken);

  if(securityService.hasBearerToken && hasValidBearerToken) {
    const currentUserData = await securityService.getCurrentUserInfo() as CurrentUserInfo;

    if (permissionService.canView(to.name!, currentUserData.role)) {
      next();
    } else {
      // TODO: Unauthorize page?
      next({ path: RouteConstants.UnauthorizedPage});
    }
  } else {
    // securityService.logout();
    next({ path: RouteConstants.LandingPage});
  }
}
