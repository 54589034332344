import Vue from 'vue'
import Component from "vue-class-component";
import { toasterService, ToasterSuccessMessage } from "@/shared/toaster-service";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { VlToaster, VlAlert } from "@govflanders/vl-ui-vue-components";
import { Watch } from "@/common/decorators/Watch";

@Component({
  components: { VlToaster, VlAlert }
})
export default class RvvToasterSuccessComponent extends Vue {
  successMessages: ToasterSuccessMessage[] = [];

  @Watch("successMessages")
  public watchSuccessMessages(val: ToasterSuccessMessage[]) {
    if (val.length > 0) {
      setTimeout(this.closeToasterMessage, 3000);
    }
  }

  mounted() {
    toasterService.successMessages.subscribe(message => this.successMessages = message);
  }

  closeToasterMessage(){
    toasterService.removeSuccessMessage();
  }
}
