import axios from "axios";
import { toasterService } from "../toaster-service";
import i18n from "@/i18n/i18n";
import { RouteConstants } from "@/router/router-constants";

export function errorInterceptor() {
  axios.interceptors.response.use(
    (response => response),
    (error) => {
      const { response } = error;
      if (!response) {
          // network error
          toasterService.addMessage(i18n.tc('global.errors.api-unavailable.title'), i18n.tc('global.errors.api-unavailable.text'));
          return Promise.reject();
      }

      if([404].includes(response.status)) {
        window.location.href= RouteConstants.NotFoundPage;
        return Promise.reject(response);
      }

      // validation errors need to be handled in the designated store
      if([422].includes(response.status)) {
        return Promise.reject(response);
      }

      if ([401, 403].includes(response.status)) {
        window.location.href= RouteConstants.LandingPage;
        return Promise.reject(response);
      }

      // console.log('error intercepter before logout');
      // securityService.logoutFromAcmIdm(false);
      // console.log('error intercepter after logout');
      const errorMessage = response.data?.title || response.statusText;
      console.error('ERROR:', errorMessage);

      const toasterServiceBlacklist = ["BasisregisterServiceError"];

      if (!toasterServiceBlacklist.includes(response.data?.code)) {
        const translatedCode = response.data?.code ? i18n.tc(`global.errors.unexpected.code-enum.${response.data.code}`) : null;
        toasterService.addMessage(i18n.tc('global.errors.unexpected.title'), i18n.tc('global.errors.unexpected.text'), translatedCode);
      }

      return Promise.reject(response);
    });
}
