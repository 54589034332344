import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "@/common/decorators/Prop";

@Component
export default class RouterLinkTileComponent extends Vue {
  @Prop({default: "#"}) to!: string;
  @Prop({default: null}) img!: string;
  @Prop({default: null}) imgAlt!: string;
  @Prop({default: null}) title!: string;
  @Prop({default: null}) description!: string;

}
