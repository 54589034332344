// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { extend } from "../../node_modules/@govflanders/vl-ui-vue-components";
import DateAdapter, { DATE_FORMAT_DEFAULTS } from "@/shared/date-adapter";
import { startsWith, exactLength, numericSqlWildcards, greaterThanOrEqualSqlMinDate, greaterThanGivenValue } from "./validators";
import { isEmpty } from "@/common/utils/typescript-utilities";
import dayjs from "dayjs";

export default {
  install() {
    extend("startsWith", {
      validate: (value: string, { prefix }: { prefix: string }) => {
        return startsWith(value, { prefix });
      },
      params: ["prefix"],
      message: "{_field_} moet beginnen met '{prefix}'",
    });

    extend('requiredWhenAndereLeeg', {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      validate: (value: string, { otherValue, otherValueFieldName }: { otherValue: string, otherValueFieldName: string }) => {
        if (!isEmpty(otherValue)) return true;
        return !!value;
      },
      computesRequired: true,
      params: ["otherValue", "otherValueFieldName"],
      message: "{_field_} is verplicht indien {otherValueFieldName} niet is ingevuld"
    })

    extend('validDate', {
      validate: (value: string) => {
        return DateAdapter.isValidDateString(value, DATE_FORMAT_DEFAULTS.displayDateOnlySlashFormat);
      },
      message: '{_field_} is geen geldige datum',
    });

    extend('greaterThanOrEqualSqlMinDate', {
      validate: (value: string) => {
        return greaterThanOrEqualSqlMinDate(value);
      } ,
      message: '{_field_} moet groter of gelijk zijn aan 01/01/1753',
    });

    extend('greaterThanOtherDate', {
      validate: (value: string, { otherDateString }: { otherDateString: string }) => {
        if (isEmpty(otherDateString)) return true;

        const date = DateAdapter.toDayjsDate(value, DATE_FORMAT_DEFAULTS.displayDateOnlySlashFormat);
        const compareToDate = DateAdapter.toDayjsDate(otherDateString, DATE_FORMAT_DEFAULTS.displayDateOnlySlashFormat);
        return DateAdapter.isGreaterThan(date, compareToDate);
      },
      params: ["otherDateString"],
      message: "{_field_} moet groter zijn dan {otherDateString}",
    });

    extend('greaterThanOrEqualOtherDate', {
      validate: (value: string, { otherDateString }: { otherDateString: string }) => {
        if (isEmpty(otherDateString)) return true;

        const date = DateAdapter.toDayjsDate(value, DATE_FORMAT_DEFAULTS.displayDateOnlySlashFormat);
        const compareToDate = DateAdapter.toDayjsDate(otherDateString, DATE_FORMAT_DEFAULTS.displayDateOnlySlashFormat);
        return DateAdapter.isGreaterThanOrEqual(date, compareToDate);
      },
      params: ["otherDateString"],
      message: "{_field_} moet groter of gelijk zijn dan {otherDateString}",
    });

    extend('greaterThanOrEqualToToday', {
      validate: (value: string) => {
        if (isEmpty(value)) return false;

        const date = DateAdapter.toDayjsDate(value, DATE_FORMAT_DEFAULTS.displayDateOnlySlashFormat);
        return DateAdapter.isGreaterThanOrEqual(date.local(), dayjs().local());
      },
      message: "{_field_} moet groter of gelijk zijn dan vandaag",
    });

    extend('beforeThanOrEqualOtherDate', {
      validate: (value: string, { otherDateString }: { otherDateString: string }) => {
        if (isEmpty(otherDateString)) return true;

        const date = DateAdapter.toDayjsDate(value, DATE_FORMAT_DEFAULTS.displayDateOnlySlashFormat);
        const compareToDate = DateAdapter.toDayjsDate(otherDateString, DATE_FORMAT_DEFAULTS.displayDateOnlySlashFormat);
        return DateAdapter.isBeforeThanOrEqual(date, compareToDate);
      },
      params: ["otherDateString"],
      message: "{_field_} moet kleiner of gelijk zijn dan {otherDateString}",
    });

    extend('exactLength', {
      validate: (value: string, { length }: { length: number }) => {
        return exactLength(value, { length })
      },
      params: ["length"],
      message: "{_field_} moet exact {length} cijfers bevatten",
    });

    extend('numericSqlWildcards', {
      validate: (value: string) => {
        return numericSqlWildcards(value);
      },
      message: "{_field_} mag enkel cijfers en wildcards (%, _) bevatten."
    });

    extend('max_value', (value: number, values: any) => {
      let numericValue = value;

      if (value.toString().includes("%")) {
        numericValue = +value.toString().replace("%", "");
      }

      if (value.toString().includes("_")) {
        numericValue = +value.toString().replace("_", "");
      }

      if (!greaterThanGivenValue(numericValue, { maxValue: values.max })) {
        return true;
      }
      return `{_field_} mag niet groter zijn dan ${values.max.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
    });
  },
};
