import { Nullable, ValueOf } from "@/common/utils/typescript-utilities";

export const TokenRoles = {
  Beheerder: 'ROLE_BEHEERDER',
  Begunstigde: 'ROLE_BEGUNSTIGDE',
  Leverancier: 'ROLE_LEVERANCIER',
} as const;

export type TokenRole = ValueOf<typeof TokenRoles>;


export const ClientRoles = {
  Beheerder: 'Beheerder',
  Begunstigde: 'Begunstigde',
  Leverancier: 'Leverancier',
} as const;

export type ClientRole = keyof typeof ClientRoles;

export const roleMapper = {
  mapRole
}

function mapRole(role: string): Nullable<ClientRole> {
  switch (role) {
    case TokenRoles.Beheerder:
      return ClientRoles.Beheerder;
    case TokenRoles.Begunstigde:
      return ClientRoles.Begunstigde;
    case TokenRoles.Leverancier:
      return ClientRoles.Leverancier;
    default:
      return null;
  }
}
