import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "@/common/decorators/Prop";

@Component({
  inheritAttrs: false
})
export default class RvvSearchComponent extends Vue {
  @Prop({ required: true }) id!: string;
  @Prop({ required: true }) name!: string;
  @Prop() value!: string;
  @Prop() modInline!: boolean;
  @Prop() modAlt!: boolean;
  @Prop() modDisableFieldReset!: boolean;
  @Prop() labelText!: string;
  @Prop() submitText!: string;
  @Prop() resetText!: string;
  @Prop() modLiveSearch!: string;

  get searchValue(): string {
    return this.value;
  }

  set searchValue(value: string) {
    this.$emit("input", value);
  }

  get classes(): any[] {
    return ["vl-search", { "vl-search--inline": this.modInline }, { "vl-search--has-input-value": this.searchValue }, { "vl-search--alt": this.modAlt }];
  }

  get showReset(): boolean {
    return !!this.searchValue;
  }

  onReset() {
    this.$emit("reset");
  }

  onSearch() {
    this.$emit("submit", this.searchValue);
  }
}
