import Vue from "vue";
import Component from "vue-class-component";
import config from "@/common/config/config";
import { securityService } from "@/shared/security/security-service";
import ConfirmCancelModalComponent from "@/common/components/modals/confirm-cancel-modal-component/ConfirmCancelModalComponent.vue";
import { Ref } from "@/common/decorators/Ref";

@Component({
  components: { ConfirmCancelModalComponent }
})
export default class IdleComponent extends Vue {
  time: any;
  confirmCancelModalComponentTime: any;
  idleLogoutMillisecondsAsSeconds = config.idleLogoutMilliseconds / 1000;
  logoutIntervalId: any;

  @Ref() confirmCancelModalComponent!: any;

  mounted() {
    this.startInactivityTime();
  }

  destroyed() {
    clearTimeout(this.time);

    document.removeEventListener('load', this.resetTimer, true);
    document.removeEventListener('scroll', this.resetTimer, true);
    document.removeEventListener('mousedown', this.resetTimer, true);
    document.removeEventListener('mousemove', this.resetTimer, true);
    document.removeEventListener('keypress', this.resetTimer, true);
  }

  onConfirm(): void {
    clearTimeout(this.confirmCancelModalComponentTime);
    clearInterval(this.logoutIntervalId);
    this.idleLogoutMillisecondsAsSeconds = config.idleLogoutMilliseconds / 1000;
    this.confirmCancelModalComponent.onCloseModal();
  }

  onCancel(): void {
    clearTimeout(this.confirmCancelModalComponentTime);
    clearInterval(this.logoutIntervalId);
    this.idleLogoutMillisecondsAsSeconds = config.idleLogoutMilliseconds / 1000;
    this.logout();
  }

  private startInactivityTime(): void {
    document.addEventListener('load', this.resetTimer, true);
    document.addEventListener('scroll', this.resetTimer, true);
    document.addEventListener('mousedown', this.resetTimer, true);
    document.addEventListener('mousemove', this.resetTimer, true);
    document.addEventListener('keypress', this.resetTimer, true);
  }

  private resetTimer(): void {
    clearTimeout(this.time);
    this.time = setTimeout(this.idleCallback, config.idleMilliseconds);
  }

  private idleCallback(): void {
    if (!this.confirmCancelModalComponent.isOpen()) {
      this.confirmCancelModalComponentTime = setTimeout(this.logout, config.idleLogoutMilliseconds);
      this.logoutIntervalId = setInterval(this.logoutTime, 1000);
      this.confirmCancelModalComponent.onOpenModal();
    }
  }

  private logoutTime(): void {
    this.idleLogoutMillisecondsAsSeconds = this.idleLogoutMillisecondsAsSeconds - 1;
  }

  private logout(): void {
    securityService.logout();
  }
}
