import Vue from 'vue'
import Component from "vue-class-component";
import {ToasterMessage, toasterService} from "@/shared/toaster-service";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { VlToaster, VlAlert } from "@govflanders/vl-ui-vue-components";

@Component({
  components: { VlToaster, VlAlert }
})
export default class RvvToasterComponent extends Vue {
  toasterMessages: ToasterMessage[] = [];

  mounted() {
    toasterService.messages.subscribe(x => this.toasterMessages = x);
  }

  closeToasterMessage(value: ToasterMessage){
    toasterService.removeMessage(value);
  }
}
