import { RouteNameConstants } from "@/router/router-constants";
import { ClientRole, ClientRoles } from "@/shared/security/role-mapper";
import { Nullable } from "@/common/utils/typescript-utilities";

export interface canViewMap {
  route: string;
  values: ClientRole[];
}

export const permissionService = {
  canView
}

const canViewDictionary: canViewMap[] = [
  { route: RouteNameConstants.StartPage, values: [ClientRoles.Beheerder, ClientRoles.Leverancier, ClientRoles.Begunstigde] },
  { route: RouteNameConstants.BeheerPage, values: [ClientRoles.Beheerder] },
  { route: RouteNameConstants.BeheerBegunstigdenPage, values: [ClientRoles.Beheerder] },
  { route: RouteNameConstants.BeheerLeveranciersPage, values: [ClientRoles.Beheerder] },
  { route: RouteNameConstants.BeheerSoorttypenPage, values: [ClientRoles.Beheerder] },
  { route: RouteNameConstants.SoorttypeToevoegenPage, values: [ClientRoles.Beheerder] },
  { route: RouteNameConstants.SoorttypeDetailPage, values: [ClientRoles.Beheerder] },
  { route: RouteNameConstants.BegunstigdeToevoegenPage, values: [ClientRoles.Beheerder] },
  { route: RouteNameConstants.BegunstigdeDetailPage, values: [ClientRoles.Beheerder] },
  { route: RouteNameConstants.LeverancierToevoegenPage, values: [ClientRoles.Beheerder] },
  { route: RouteNameConstants.LeverancierDetailPage, values: [ClientRoles.Beheerder] },
  { route: RouteNameConstants.KadastraleToestandPage, values: [ClientRoles.Beheerder] },
  { route: RouteNameConstants.KadastraleToestandToevoegenPage, values: [ClientRoles.Beheerder] },
  { route: RouteNameConstants.KadastraleToestandDetailPage, values: [ClientRoles.Beheerder] },
  { route: RouteNameConstants.LeveringenPage, values: [ClientRoles.Beheerder, ClientRoles.Leverancier] },
  { route: RouteNameConstants.LeveringToevoegenPage, values: [ClientRoles.Leverancier] },
  { route: RouteNameConstants.ThemabestandPage, values: [ClientRoles.Beheerder, ClientRoles.Leverancier, ClientRoles.Begunstigde] },
  { route: RouteNameConstants.DownloadPage, values: [ClientRoles.Beheerder, ClientRoles.Leverancier] },
  { route: RouteNameConstants.DownloadVoorkooprechtenAanvraagPage, values: [ClientRoles.Beheerder, ClientRoles.Leverancier] },
  { route: RouteNameConstants.DownloadVoorkooprechtenPage, values: [ClientRoles.Beheerder, ClientRoles.Leverancier] },
  { route: RouteNameConstants.DownloadReferentieDataPage, values: [ClientRoles.Beheerder, ClientRoles.Leverancier] },
  { route: RouteNameConstants.RegistratiePrivatePage, values: [ClientRoles.Beheerder, ClientRoles.Leverancier, ClientRoles.Begunstigde] },
  { route: RouteNameConstants.RegistratieSuccessPrivatePage, values: [ClientRoles.Beheerder, ClientRoles.Leverancier, ClientRoles.Begunstigde] },
  { route: RouteNameConstants.PerceelHistoriekPage, values: [ClientRoles.Beheerder] },
  { route: RouteNameConstants.InstellingenPage, values: [ClientRoles.Beheerder] },
  { route: RouteNameConstants.DashboardPage, values: [ClientRoles.Beheerder] },
];

function canView(key: string, role: Nullable<ClientRole>): boolean {
  if (!role) {
    return false;
  }

  const permissionMapForKey = canViewDictionary.find(permission => permission.route === key);
  if (!permissionMapForKey) {
    return false;
  }

  return permissionMapForKey.values.some(value => value === role);
}
