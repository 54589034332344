import Vue from "vue";
import i18n from "@/i18n/i18n";
import Component from "vue-class-component";
import { Prop } from "@/common/decorators/Prop";
import { Nullable } from "@/common/utils/typescript-utilities";
import { Ref } from "@/common/decorators/Ref";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import vue2Dropzone from "vue2-dropzone";

@Component({
  components: { vueDropzone: vue2Dropzone },
})
export default class VerticalUploadFormComponent extends Vue {
  @Prop({ required: true, default: null }) label!: string;
  @Prop({ default: false }) showAnnotation!: boolean;
  @Prop({ default: null }) annotation!: string;
  @Prop({ default: "" }) rules!: string;
  @Prop({ required: true }) allowedFileTypes!: string;
  @Prop({ required: true }) allowedExtensions!: string;
  @Prop({ default: 1 }) maxFiles!: number;
  @Prop({ required: true }) maxFileSize!: number;

  @Ref() dropzone!: any;
  @Ref() provider!: any;

  file: Nullable<any> = null;
  hasReachedMaxFiles = false;
  hasUploadError = false;
  dropzoneOptions: any;

  created() {
    this.dropzoneOptions = {
      url: 'http://localhost',
      autoProcessQueue: false, // this is important as you dont want form to be submitted unless you have clicked the submit button
      thumbnailWidth: 200,
      addRemoveLinks: false,
      previewTemplate: this.getPreviewTemplate(),
      acceptedFiles: this.allowedFileTypes,
      maxFilesize: this.maxFileSize,
      maxFiles: this.maxFiles,
      dictFileTooBig: i18n.tc("global.upload.filesize-too-big"),
      dictInvalidFileType: i18n.tc("global.upload.invalid-file-type"),
      clickable: true,
      accept: function(file: any, done: any) {
        if (file.size === 0) {
          done(i18n.tc("global.upload.empty-file"));
        }
        else {
          done();
        }
      }
    }
  }

  async fileAdded(file: any) {
    if (this.file === null) {
      this.file = file;
    }

    await this.validateFile();

    if (file.status === "error") return;

    file.status = "success";
    this.disable();
  }

  async validateFile() {
    await this.provider.validate(this.file);
  }

  removeFile() {
    this.file = null;
    this.hasUploadError = false;
    this.enable();
  }

  getPreviewTemplate() {
    return `
        <div class="vl-upload__file">
          <p class="vl-upload__file__name">
            <span class="vl-upload__file__name__icon vl-vi vl-vi-document" aria-hidden="true"></span><span data-dz-name></span>
            <span class="vl-upload__file__size">(<span data-dz-size></span>)</span>
          </p>
          <div class="dz-error-message"><span data-dz-errormessage></div>
          <button type="button" class="vl-upload__file__close vl-link vl-link--icon" data-dz-remove><span class="vl-vi vl-vi-cross" aria-hidden="true"></span></button>
        </div>`;
  }

  getClasses(errors: any) {
    if (errors.length > 0) {
      return "vl-upload--error";
    }
    if (this.hasReachedMaxFiles) {
      return "vl-upload--disabled";
    }
  }

  fileUploadSuccess() {
    this.hasUploadError = false;
    this.disable();
  }

  fileUploadError() {
    this.hasUploadError = true;
    this.disable();
  }

  disable() {
    this.hasReachedMaxFiles = true;
    this.dropzone.disable();
  }

  enable() {
    this.hasReachedMaxFiles = false;
    this.dropzone.enable();
  }
}
